import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { UtilityService } from './utility.service';
import {
  FmResponse,
  SingleCourseOrClassQuery,
} from 'src/app/_components/pages/education/interfaces/filemaker-query.interface';
import { NamsgognRes } from '../_components/pages/education/interfaces/namsgogn.interface';
import {
  Namskeid,
  NamskeidRes,
  PortalData,
} from '../_components/pages/education/interfaces/namskeid.interface';
import {
  Category,
  PlannedCourse as PlannedCourseWithQueryParamsResponse,
  FilterParams,
  CourseMap,
  CourseVariantMap,
  CourseLocationMap,
  PlannedCourseMap,
  SubCategoryMap,
  LabelMap,
  CatalogProductMap,
  CatalogVariantMap,
  TeacherMap,
  TeacherEnrollmentMap,
  TeacherEnrollmentMapArr,
  CourseTabMap,
  ProgramEditionMap,
  ProgramMap,
  MeetingMap,
} from './interfaces/fake-maker.interface';
import { environment } from 'src/environments/environment';
import {
  EducatorWithIncludes,
  GetMeetingsResponse,
  GetCourseVariantsResponse,
  GetCourseLocationsResponse,
  GetLabelsData,
  Label,
  GetCourseTabsResponse,
  GetProgramEditionsResponse,
  GetCoursesResponse,
  GetTeachersResponse,
  GetCatalogProductsData,
  GetCatalogProductsResponse,
  GetCatalogVariantsData,
  GetCatalogVariantsResponse,
  GetTeacherEnrollmentsResponse,
  GetPlannedCoursesResponse,
  GetProgramsResponse,
  OpenAPI,
  GetMeetingsByPlannedCourseIdData,
  MeetingWithIncludes,
  GetMeetingsByPlannedCourseIdResponse,
} from 'src/eduframe';
import {
  CatalogVariantCustom,
  CatalogProductCustom,
} from './interfaces/fake-maker.interface';
import { TokenService } from './token.service';
import { BehaviorSubject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { response } from 'express';

@Injectable({
  providedIn: 'root',
})
export class FakeMakerService {
  bearerToken = new BehaviorSubject(null);
  constructor(
    @Inject(PLATFORM_ID) public platformId: any,
    private apiService: ApiService,
    private utilityService: UtilityService,
    private router: Router,
    private tokenService: TokenService
  ) {
    if (isPlatformBrowser(this.platformId)) {
      OpenAPI.BASE = '/api/eduframe';
    } else {
      OpenAPI.BASE = process.env.EDUFRAME_API_ENDPOINT;
      OpenAPI.TOKEN = process.env.EDUFRAME_API_KEY;

      if (process.env.EDUFRAME_API_LOOPBACK == '1') {
        OpenAPI.BASE = `http://localhost:${process.env.PORT}/api/eduframe`;
      }
    }
  }

  async getSemesters(): Promise<FmResponse> {
    console.trace('FakeMakerService getSemesters');
    throw new Error('Method not implemented.');
  }

  async getFilters(detailType, key): Promise<FmResponse> {
    const response = await this.apiService.getAllCategories();
    let data: any[] = [];

    if (detailType === 'Fis_Yfirflokkur/_find') {
      response.map((category: Category) => {
        if (!category.parent_id) {
          const responseData = this.EduframeCategoriesToFakeMakerCourse(
            category,
            environment.eduframe.endpoint
          );

          data.push({
            fieldData: responseData,
            portalData: {},
            modId: '',
            recordId: '',
          });
        }
      });
    } else if (detailType === 'Fis_Undirflokkur/_find') {
      response.map((category: Category) => {
        if (category.parent_id) {
          const responseData = this.EduframeCategoriesToFakeMakerCourse(
            category,
            environment.eduframe.endpoint
          );

          data.push({
            fieldData: responseData,
            portalData: {},
            modId: '',
            recordId: '',
          });
        }
      });
    } else {
      response.map((category: Category) => {
        const responseData = this.EduframeCategoriesToFakeMakerCourse(
          category,
          environment.eduframe.endpoint
        );

        data.push({
          fieldData: responseData,
          portalData: {},
          modId: '',
          recordId: '',
        });
      });
    }

    return {
      messages: [
        {
          code: '200',
          message: 'Success',
        },
      ],
      response: {
        data,
      },
    } as FmResponse;
  }

  async getCourses(
    offset?,
    limit?,
    params?: FilterParams
  ): Promise<FmResponse> {
    const {
      plannedCourseResponse,
      categories,
      courses,
      courseVariants,
      courseLocations,
      parentCategories,
      subCategoriesMap,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      times, // Times array with meeting times or '00:00'
    } = await this.fetchAndPrepereCourseData(params);




    const courseMap = await this.generateCourseMap(courses);
    const courseVariantMap = await this.generateCourseVariantMap(courseVariants);
    const courseLocationMap = await this.generateCourseLocationMap(courseLocations);
    const catalogProductMap = await this.generateCatalogProductMap(catalog_products, 'productable_id');
    const catalogVariantMap = await this.generateCatalogVariantMap(catalog_variants, 'variantable_id');
    const teacherMap = await this.generateTeacherMap(teachers);
    const teacherEnrollmentMapByPlannedCourseId = await this.generateTeacherEnrollmentMapArr(
      teacher_enrollments,
      'planned_course_id'
    );

    const sub_categories = categories.filter(category => category.parent_id);
    const parent_categories = categories.filter(category => !category.parent_id);

    const mapper = {};
    sub_categories.map(sub_category => {
      const parent_category = parent_categories.find(
        parent_category => parent_category.id === sub_category.parent_id
      );
      const sub_category_id = sub_category.id;
      const parent_category_name = parent_category.name;
      mapper[sub_category_id] = parent_category_name;
    });

    let plannedCoursesArr: PlannedCourseWithQueryParamsResponse[] = [];

    plannedCourseResponse.forEach((plannedCourse, index) => {
      const teacherEnrollment = teacherEnrollmentMapByPlannedCourseId[plannedCourse.id];
      let teachers = [];
      teacherEnrollment?.forEach(enrollment => {
        const teacher = teacherMap[enrollment.teacher_id];
        teachers.push(teacher);
      });

      const course = courseMap[plannedCourse.course_id];
      const courseVariant = courseVariantMap[plannedCourse.course_variant_id];
      const courseLocation = courseLocationMap[plannedCourse.course_location_id];

      const catalogProduct = catalogProductMap[plannedCourse.course_id];
      const catalogVariant = catalogVariantMap[plannedCourse.id];

      // Use the times array to assign the correct meeting time
      const meetingTime = times[index] || '00:00';

      plannedCoursesArr.push({
        ...plannedCourse,
        course,
        course_variant: courseVariant,
        course_location: courseLocation,
        teachers: teachers,
        catalog_product: catalogProduct,
        catalog_variant: catalogVariant,
        meetingTime, // Add the meeting time from the times array
      });
    });


    const today = new Date();

    const data = plannedCoursesArr;


    const res = this.eduframePlannedCoursesResponseToFakeMakerResponse(data, 'Námskeið');


    return res;
  }


  async getClasses(
    offset?,
    limit?,
    params?: FilterParams
  ): Promise<FmResponse> {
    const {
      plannedCourseResponse,
      categories,
      courses,
      courseVariants,
      courseLocations,
      parentCategories,
      subCategoriesMap,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
    } = await this.fetchAndPrepereClassData(params);

    const courseMap = await this.generateCourseMap(courses);
    const courseVariantMap = await this.generateCourseVariantMap(
      courseVariants
    );
    const courseLocationMap = await this.generateCourseLocationMap(
      courseLocations
    );
    const catalogProductMap = await this.generateCatalogProductMap(
      catalog_products,
      'productable_id'
    );
    const catalogVariantMap = await this.generateCatalogVariantMap(
      catalog_variants,
      'variantable_id'
    );
    const course_label_map = await this.generateLabelMap(label_courses);
    const teacherMap = await this.generateTeacherMap(teachers);
    const teacherEnrollmentMapByPlannedCourseId =
      await this.generateTeacherEnrollmentMapArr(
        teacher_enrollments,
        'planned_course_id'
      );

    const sub_categories = categories.filter(category => category.parent_id);
    const parent_categories = categories.filter(
      category => !category.parent_id
    );

    const mapper = {};
    sub_categories.map(sub_category => {
      const parent_category = parent_categories.find(
        parent_category => parent_category.id === sub_category.parent_id
      );
      const sub_category_id = sub_category.id;
      const parent_category_name = parent_category.name;
      mapper[sub_category_id] = parent_category_name;
    });

    let plannedCoursesArr: PlannedCourseWithQueryParamsResponse[] = [];
    plannedCourseResponse.forEach(async plannedCourse => {
      const teacherEnrollment =
        teacherEnrollmentMapByPlannedCourseId[plannedCourse.id];
      let teachers = [];
      teacherEnrollment?.forEach(enrollment => {
        const teacher = teacherMap[enrollment.teacher_id];
        teachers.push(teacher);
      });

      const course = courseMap[plannedCourse.course_id];
      const courseVariant = courseVariantMap[plannedCourse.course_variant_id];
      const courseLocation =
        courseLocationMap[plannedCourse.course_location_id];

      const catalogProduct = catalogProductMap[plannedCourse.course_id];
      const catalogVariant = catalogVariantMap[plannedCourse.id];

      const labels = () => {
        return course.label_ids.map(label_id => {
          return course_label_map[label_id];
        });
      };

      plannedCoursesArr.push({
        ...plannedCourse,
        course,
        course_variant: courseVariant,
        course_location: courseLocation,
        teachers: teachers,
        catalog_product: catalogProduct,
        catalog_variant: catalogVariant,
        labels: labels(),
      });
    });

    const data = plannedCoursesArr.filter(plannedCourse => {
      const course = plannedCourse.course;
      if (course == undefined) {
        return false;
      }
      const category_id = course.category_id;
      const parent_category_name = mapper[category_id];
      const startDate = new Date(plannedCourse.start_date);

      // Include only published courses starting today or later
      return parent_category_name === 'Námsbrautir' && course.is_published;
    });

    const res = this.eduframePlannedCoursesResponseToFakeMakerResponse(
      data,
      'Námsbrautir'
    );

    return res;
  }

  async getCoursesInEnglish(offset?, limit?): Promise<FmResponse> {
    console.trace('FakeMakerService getCoursesInEnglish', { offset, limit });
    throw new Error('Method not implemented.');
  }

  async getClassesAndCourses(
    limit,
    offset?,
    params: FilterParams = {}
  ): Promise<FmResponse> {
    // Fetch both classes and courses data
    const [coursesData, classesData] = await Promise.all([
      this.fetchAndPrepereCourseData(params),
      this.fetchAndPrepereClassData(params),
    ]);

    // Generate necessary maps
    const courseMap = {
      ...(await this.generateCourseMap(coursesData.courses)),
      ...(await this.generateCourseMap(classesData.courses)),
    };

    const courseVariantMap = {
      ...(await this.generateCourseVariantMap(coursesData.courseVariants)),
      ...(await this.generateCourseVariantMap(classesData.courseVariants)),
    };

    const courseLocationMap = {
      ...(await this.generateCourseLocationMap(coursesData.courseLocations)),
      ...(await this.generateCourseLocationMap(classesData.courseLocations)),
    };

    const teacherMap = {
      ...(await this.generateTeacherMap(coursesData.teachers)),
      ...(await this.generateTeacherMap(classesData.teachers)),
    };

    const teacherEnrollmentMapByPlannedCourseId = {
      ...(await this.generateTeacherEnrollmentMapArr(
        coursesData.teacher_enrollments,
        'planned_course_id'
      )),
      ...(await this.generateTeacherEnrollmentMapArr(
        classesData.teacher_enrollments,
        'planned_course_id'
      )),
    };

    const catalogProductMap = {
      ...(await this.generateCatalogProductMap(
        coursesData.catalog_products,
        'productable_id'
      )),
      ...(await this.generateCatalogProductMap(
        classesData.catalog_products,
        'productable_id'
      )),
    };

    const catalogVariantMap = {
      ...(await this.generateCatalogVariantMap(
        coursesData.catalog_variants,
        'variantable_id'
      )),
      ...(await this.generateCatalogVariantMap(
        classesData.catalog_variants,
        'variantable_id'
      )),
    };

    // Combine planned courses
    const combinedPlannedCourses = [
      ...coursesData.plannedCourseResponse,
      ...classesData.plannedCourseResponse,
    ];

    // Combine times for both courses and classes
    const combinedTimes = [...coursesData.times, ...classesData.times];

    let combinedData: PlannedCourseWithQueryParamsResponse[] = [];

    combinedPlannedCourses.forEach((plannedCourse, index) => {
      const teacherEnrollment =
        teacherEnrollmentMapByPlannedCourseId[plannedCourse.id] || [];
      const teachers = teacherEnrollment.map(
        enrollment => teacherMap[enrollment.teacher_id]
      );

      const course = courseMap[plannedCourse.course_id];
      const courseVariant = courseVariantMap[plannedCourse.course_variant_id];
      const courseLocation = courseLocationMap[plannedCourse.course_location_id];

      // Assign meeting time from combinedTimes
      const meetingTime = combinedTimes[index] || '00:00';

      if (course) {
        combinedData.push({
          ...plannedCourse,
          course,
          course_variant: courseVariant,
          course_location: courseLocation,
          teachers,
          catalog_product: catalogProductMap[plannedCourse.course_id],
          catalog_variant: catalogVariantMap[plannedCourse.id],
          meetingTime, // Add meeting time here
        });
      }
    });

    // Sort combined data by start date
    combinedData.sort((a, b) => {
      return (
        new Date(a.start_date).getTime() - new Date(b.start_date).getTime()
      );
    });

    return this.eduframePlannedCoursesResponseToFakeMakerResponse(
      combinedData,
      'Both'
    );
  }


  async getSingleCourseOrClass(
    customQuery: SingleCourseOrClassQuery | any
  ): Promise<NamskeidRes | FmResponse> {
    const type = customQuery.query[0].type;
    const course_id = customQuery.query[0].course_id as unknown as number;
    const planned_course_id = customQuery.query[0]
      .planned_course_id as unknown as number;

    const data =
      type === 'course'
        ? await this.fetchAndPrepereSingleCourseData(
            course_id,
            planned_course_id
          )
        : await this.fetchAndPrepereSingleClassData(
            course_id,
            planned_course_id
          );

    const res = this.eduframeCourseResponseToFakeMakerResponse(
      data,
      type === 'course' ? 'Námskeið' : 'Námsbrautir'
    );

    return res;
  }

  async getStudyMaterials(query): Promise<NamsgognRes> {
    console.trace('FakeMakerService getStudyMaterials', { query });
    throw new Error('Method not implemented.');
  }

  async filterCourses(
    idYfirflokkur,
    params: FilterParams,
    limit?,
    offset?
  ): Promise<FmResponse> {

    console.log("inside filterCourses");
    // If idYfirflokkur indicates "classes" (Y0004), then use getClasses.
    if (idYfirflokkur === 'Y0004') {
      const res = await this.getClasses(limit, offset, params);
      return res;
    } else if (idYfirflokkur !== '*') {
      // (Your existing branch for non-* values)
      const {
        plannedCourseResponse,
        categories,
        courses,
        courseVariants,
        courseLocations,
        parentCategories,
        subCategoriesMap,
        label_courses,
        label_teachers,
        teachers,
        catalog_products,
        catalog_variants,
        teacher_enrollments,
        times,
      } = await this.fetchAndPrepereCourseData(params);

      const courseMap = await this.generateCourseMap(courses);
      const courseVariantMap = await this.generateCourseVariantMap(courseVariants);
      const courseLocationMap = await this.generateCourseLocationMap(courseLocations);
      const catalogProductMap = await this.generateCatalogProductMap(catalog_products, 'productable_id');
      const catalogVariantMap = await this.generateCatalogVariantMap(catalog_variants, 'variantable_id');
      const teacherMap = await this.generateTeacherMap(teachers);
      const teacherEnrollmentMapByPlannedCourseId = await this.generateTeacherEnrollmentMapArr(
        teacher_enrollments,
        'planned_course_id'
      );

      // Map subcategory IDs to parent category names
      const mapper = {};
      categories
        .filter(category => category.parent_id)
        .forEach(sub_category => {
          const parent = categories.find(parent => parent.id === sub_category.parent_id);
          mapper[sub_category.id] = parent ? parent.name : null;
        });

      let plannedCoursesArr: PlannedCourseWithQueryParamsResponse[] = [];

      // Process each planned course
      for (const [index, plannedCourse] of plannedCourseResponse.entries()) {
        const teacherEnrollment = teacherEnrollmentMapByPlannedCourseId[plannedCourse.id];
        const teachersArr = teacherEnrollment?.map(enrollment => teacherMap[enrollment.teacher_id]) || [];

        const course = courseMap[plannedCourse.course_id];
        const courseVariant = courseVariantMap[plannedCourse.course_variant_id];
        const courseLocation = courseLocationMap[plannedCourse.course_location_id];
        const catalogProduct = catalogProductMap[plannedCourse.course_id];
        const catalogVariant = catalogVariantMap[plannedCourse.id];

        // Use corresponding time from times array
        const meetingTime = times[index] || '00:00';

        plannedCoursesArr.push({
          ...plannedCourse,
          course,
          course_variant: courseVariant,
          course_location: courseLocation,
          teachers: teachersArr,
          catalog_product: catalogProduct,
          catalog_variant: catalogVariant,
          meetingTime,
        });
      }

      // Filter the planned courses based on category and start date
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const data = plannedCoursesArr.filter(plannedCourse => {
        const course = plannedCourse.course;
        if (!course) return false;
        const category_id = course.category_id;
        const parentCategoryName = mapper[category_id];
        const startDate = new Date(plannedCourse.start_date);
        const category_filter = idYfirflokkur === 'Y0004' ? 'Námskeið' : 'Námsbrautir';
        // (Include remote filtering if needed here)
        return parentCategoryName === category_filter && startDate >= today;
      });

      return this.eduframePlannedCoursesResponseToFakeMakerResponse(data);
    } else {
      // NEW: If idYfirflokkur is '*' and a search query is provided,
      // then use getClasses instead of getClassesAndCourses
      // if (params?.search && params.search.trim() !== '') {
      //   const res = await this.getClasses(limit, offset, params);
      //   console.log("res i => " + res);
      //   return res;
      // } else {
      //   const res = await this.getClassesAndCourses(limit, offset, params);
      //   return res;
      // }

      // const res = await this.getClassesAndCourses(limit, offset, params);
      const resOne = await this.getClasses(limit, offset, params);
      const resTwo = await this.getCourses(limit, offset, params);

      const dataOne = resOne.response.data;
      const dataTwo = resTwo.response.data;

      console.log("dataOne => " + dataOne);
      console.log("dataTwo => " + dataTwo);

      const data = [...dataOne, ...dataTwo];

      const response = {
        messages: [
          {
            code: '200',
            message: 'Success',
          },
        ],
        response: {
          data,
        },
      } as FmResponse;
      return response;
    }
  }


  async registerCourse(query, files?): Promise<FmResponse> {
    console.trace('FakeMakerService registerCourse', { query, files });
    throw new Error('Method not implemented.');
  }

  clearSortingAndFilters(): void {
    this.router.navigate([], { queryParams: {} });
  }

  inDateRange(): string {
    return this.utilityService.inDateRange();
  }

  assignBearerToken(): void {
    this.tokenService.assignBearerToken();
  }

  // Helper methods

  async eduframeCourseResponseToFakeMakerResponse(
    response: PlannedCourseWithQueryParamsResponse,
    parentCategory?: string,
    subCategory?: string
  ): Promise<FmResponse> {
    const messages = [
      {
        code: '200',
        message: 'Success',
      },
    ];
    let data: any[] = [];

    const extra_review = new Map<string, string>();
    extra_review.set('Auka_Umsagnir_Vefur::Nafn', 'Test name of Extra Review');
    extra_review.set(
      'Auka_Umsagnir_Vefur::Umsöng',
      'Test review of Extra Review'
    );
    extra_review.set(
      'Auka_Umsagnir_Vefur::Mynd',
      environment.eduframe.endpoint + 'test-image.jpg'
    );

    const responseData = this.EduframePlannedCourseToFakeMakerCourse(
      response,
      environment.eduframe.endpoint,
      parentCategory,
      subCategory
    );

    const portalData = this.createPortalData(
      environment.eduframe.endpoint + response.course.avatar,
      environment.eduframe.endpoint + response.course.avatar_url,
      [extra_review]
    );
    data.push({
      fieldData: responseData,
      portalData: portalData,
      modId: '',
      recordId: '',
    });

    return {
      messages,
      response: {
        data,
      },
    } as FmResponse;
  }

  async eduframePlannedCoursesResponseToFakeMakerResponse(
    response: PlannedCourseWithQueryParamsResponse[],
    parentCategory?: string,
    subCategory?: string
  ): Promise<FmResponse> {

    const messages = [
      {
        code: '200',
        message: 'Success',
      },
    ];

    let data: any[] = [];

    const extra_review = new Map<string, string>();
    extra_review.set('Auka_Umsagnir_Vefur::Nafn', 'Test name of Extra Review');
    extra_review.set(
      'Auka_Umsagnir_Vefur::Umsöng',
      'Test review of Extra Review'
    );
    extra_review.set(
      'Auka_Umsagnir_Vefur::Mynd',
      environment.eduframe.endpoint + 'test-image.jpg'
    );

    response.map((course: PlannedCourseWithQueryParamsResponse) => {
      if (parentCategory === 'Both') {
        // check if course is Námskeið or Námsbrautir
        const productable_type = course.catalog_product?.productable_type;

        if (productable_type === 'Course') {
          const responseData = this.EduframePlannedCourseToFakeMakerCourse(
            course,
            environment.eduframe.endpoint,
            'Námskeið'
          );
          const portalData = this.createPortalData(
            environment.eduframe.endpoint + course.course.avatar,
            environment.eduframe.endpoint + course.course.avatar_url,
            [extra_review]
          );
          data.push({
            fieldData: responseData,
            portalData: portalData,
            modId: '',
            recordId: '',
            eduframeCourse: course.course,
            eduframePlannedCourse: course,
          });
        } else {
          const responseData = this.EduframePlannedCourseToFakeMakerCourse(
            course,
            environment.eduframe.endpoint,
            'Námsbrautir'
          );
          const portalData = this.createPortalData(
            environment.eduframe.endpoint + course.course.avatar,
            environment.eduframe.endpoint + course.course.avatar_url,
            [extra_review]
          );
          data.push({
            fieldData: responseData,
            portalData: portalData,
            modId: '',
            recordId: '',
            eduframeCourse: course.course,
            eduframePlannedCourse: course,
          });
        }
      } else {
        const responseData = this.EduframePlannedCourseToFakeMakerCourse(
          course,
          environment.eduframe.endpoint,
          parentCategory
        );
        const portalData = this.createPortalData(
          environment.eduframe.endpoint + course.course.avatar,
          environment.eduframe.endpoint + course.course.avatar_url,
          [extra_review]
        );
        data.push({
          fieldData: responseData,
          portalData: portalData,
          modId: '',
          recordId: '',
          eduframeCourse: course.course,
          eduframePlannedCourse: course,
        });
      }
    });


    return {
      messages,
      response: {
        data,
      },
    } as FmResponse;
  }

  EduframePlannedCourseToFakeMakerCourse(
    course: PlannedCourseWithQueryParamsResponse,
    url: string,
    parentCategory?: string,
    subCategory?: string,
  ): Namskeid {
    const variant = course.course_variant;
    const teachers = course.teachers || [];

    const getFjarkennsla = (name?: string) => {
      switch (name) {
        case 'Staðnám':
          return 'nei';
        case 'Fjarnám':
          return 'já';
        case 'Stað- og fjarnám':
          return 'bæði';
        case 'Viðburður':
          return 'Viðburður';
        default:
          return '';
      }
    };

    const getFjarkennsla_ = (name?: string) => {
      switch (name) {
        case 'Staðnám':
          return 'false';
        case 'Fjarnám':
          return 'true';
        case 'Stað- og fjarnám':
          return 'true';
        case 'Viðburður':
          return 'vidburdur';
        default:
          return '';
      }
    };

    const getCustomCatalogVariant = (
      custom: unknown | CatalogVariantCustom
    ) => {
      if (custom) {
        return custom as CatalogVariantCustom;
      } else {
        return {} as CatalogVariantCustom;
      }
    };

    const getCustomCatalogProduct = (
      custom: unknown | CatalogProductCustom
    ) => {
      if (custom) {
        return custom as CatalogProductCustom;
      }
      return {} as CatalogProductCustom;
    };

    const course_tab_contents =
      course.catalog_product?.course_tab_contents?.map(content => {
        return content.content;
      });

    const ID_Yfirflokkur = () => {
      if (parentCategory === 'Námsbrautir') {
        return 'Y0004';
      } else {
        return 'Y0003';
      }
    };

    const ID_Undirflokkur = () => {
      if (parentCategory !== 'Námsbrautir') {
        return '';
      }

      if (
        !course ||
        !Array.isArray(course.labels) ||
        course.labels.length === 0
      ) {
        return '';
      }

      switch (course.labels[0].name) {
        case 'Nám án eininga':
          return 'U0014';
        case 'Nám á grunnstigi háskóla':
          return 'U0012';
        case 'Nám á framhaldsstigi háskóla':
          return 'U0013';
        default:
          return '';
      }
    };

    const Tegund_eydublads = () => {
      if (parentCategory === 'Námsbrautir') {
        return '3';
      } else {
        return '';
      }
    };

    const customCatalogVariant = getCustomCatalogVariant(
      course.catalog_variant?.custom
    );
    const customCatalogProduct = getCustomCatalogProduct(
      course.catalog_product?.custom
    );

    let teacherString = '';
    for (let i = 0; i < teachers.length; i++) {
      teacherString += `${teachers[i]?.first_name ?? ''} ${
        teachers[i]?.middle_name ?? ''
      } ${teachers[i]?.last_name ?? ''}`;

      if (i === teachers.length - 2) {
        teacherString += ' og ';
      } else if (i !== teachers.length - 1) {
        teacherString += ', ';
      }
    }

    const lengd_i_klst = () => {
      const klst = customCatalogVariant?.['lengd-i-klst'];
      if (klst) {
        const numberKlst = parseFloat(klst);

        if (numberKlst % 1 === 0) {
          return parseInt(numberKlst.toString());
        } else if (numberKlst % 1 === 0.5) {
          return numberKlst.toFixed(1);
        }
      }
      return '';
    };

    const courseFakeMaker = {
      Annir: '*',
      Byrjar_Dags: course.start_date,
      Byrjar_Dags_: course.start_date,
      Dags_Lidinn: 0,
      Endar_Dags: course.end_date,
      Endar_Dags_: course.end_date,
      Fagrad: '',
      Fagleg_Umsjon: '',
      Fag_Umsjon_Mynd: url + course.course.avatar_url,
      Fag_Umsjon_netfang: teachers.length > 0 ? teachers[0]?.email : '',
      Fag_Umsjon_simi: '',
      Fag_Umsjon_almennt: '',
      Fjarkennsla_: getFjarkennsla_(course.course_variant?.name ?? ''),
      Fjarkennsla: getFjarkennsla(course.course_variant?.name ?? ''),
      Fjoldi_laus_saeti: course.catalog_variant?.available_places ?? '',
      Greining_Vefur: '',
      Haefnisvidmid_Avinningur: '',
      Hefst_KL: course.meetingTime,
      Heiti: course.course.name.toString(),
      ID: course.course.id,
      ID_Namskeidaflokkur: course.course.category_id.toString(),
      ID_Undirflokkur: ID_Undirflokkur(),
      ID_Yfirflokkur: ID_Yfirflokkur(),
      Kennari: teacherString,
      Kennslumat: '',
      Kynning: '',
      Lanamoguleikar_Styrkir: '',
      Lanshaeft: '',
      Lengd: lengd_i_klst(),
      Lokadags_Skraningar: '',
      Lykur_KL: course.course.duration,
      Lysing_flokkud_1:
        course_tab_contents && course_tab_contents[0]
          ? course_tab_contents[0]
          : '',
      Lysing_flokkud_2:
        course_tab_contents && course_tab_contents[1]
          ? course_tab_contents[1]
          : '',
      Lysing_flokkud_3:
        course_tab_contents && course_tab_contents[2]
          ? course_tab_contents[2]
          : '',
      Lysing_flokkud_4:
        course_tab_contents && course_tab_contents[3]
          ? course_tab_contents[3]
          : '',
      Lysing_flokkud_5:
        course_tab_contents && course_tab_contents[4]
          ? course_tab_contents[4]
          : '',
      Titill_flokkud_1:
        course.course_tab_titles && course.course_tab_titles.length > 0
          ? course.course_tab_titles[0]
          : '',
      Titill_flokkud_2:
        course.course_tab_titles && course.course_tab_titles.length > 1
          ? course.course_tab_titles[1]
          : '',
      Titill_flokkud_3:
        course.course_tab_titles && course.course_tab_titles.length > 2
          ? course.course_tab_titles[2]
          : '',
      Titill_flokkud_4:
        course.course_tab_titles && course.course_tab_titles.length > 3
          ? course.course_tab_titles[3]
          : '',
      Titill_flokkud_5:
        course.course_tab_titles && course.course_tab_titles.length > 4
          ? course.course_tab_titles[4]
          : '',
      Markmid: '',
      Mynd: url + course.course.avatar,
      Mynd_Vefslod: url + course.course.avatar_url,
      Myndband_Mynd_Vefslod: '',
      Myndband_Vefslod: '',
      Namskeid_Id: course.course.id,
      Namskeidsflokkur: '',
      Namsefni: '',
      Namsmat: '',
      Numer: '',
      Nytt_Endurtekid_: 'true',
      Pdf_Slod: '',
      Samvinnutexti: '',
      Skilmalar: customCatalogProduct?.skilmalar ?? '',
      Stadsetning: course.course_location?.name,
      Stjornur: customCatalogProduct?.stjornur ?? '',
      Stutt_Lysing: course.course.meta_description,
      Stutt_Lysing_Ny: course.course.meta_description,
      Taekjabunadur: '',
      Tegund_eydublads: Tegund_eydublads(),
      Textaleit: '',
      Timi: customCatalogVariant?.timi ?? '',
      Umsagnir: course?.course?.custom['umsagnir'] ?? '',
      Umsoknarferli: '',
      Umsjon: '',
      Umsjon_almennt: '',
      Umsjon_Mynd: url + course.course.avatar_url,
      Umsjon_netfang: '',
      Umsjon_simi: '',
      Undirflokkur: course.course.category_id.toString(),
      Undirtitill: '',
      Verd: parseFloat(course.cost),
      Verd_Snemmskraning: '',
      Text_Snemmskraning:
        customCatalogVariant?.['snemmskraningardagsetning-og-almennt-verd'] ??
        '',
      Yfirflokkur: '',
      rafraenskjol: '',
    } as unknown as Namskeid;
    return courseFakeMaker;
  }

  EduframeCategoriesToFakeMakerCourse(
    category: Category,
    url: string
  ): Namskeid {
    const courseFakeMaker = {
      Annir: 'Test Annir',
      Byrjar_Dags: '',
      Byrjar_Dags_: '',
      Dags_Lidinn: 0,
      Endar_Dags: '',
      Endar_Dags_: '',
      Fagrad: '',
      Fagleg_Umsjon: '',
      Fag_Umsjon_Mynd: url + category.avatar_url,
      Fag_Umsjon_netfang: '',
      Fag_Umsjon_simi: '',
      Fag_Umsjon_almennt: '',
      Fjarkennsla_: '',
      Fjarkennsla: '',
      Fjoldi_laus_saeti: '99',
      Greining_Vefur: '',
      Haefnisvidmid_Avinningur: '',
      Hefst_KL: '00:00',
      Heiti: category.name.toString(),
      ID: category.id,
      ID_Namskeidaflokkur: 'category.category_id.toString()',
      ID_Undirflokkur: 'course.course.category_id.toString()',
      ID_Yfirflokkur: 'Test Yfirflokkur Auðkenni',
      Kennari: '<Nafn Kennara>',
      Kennslumat: '',
      Kynning: '',
      Lanamoguleikar_Styrkir: '',
      Lanshaeft: '',
      Lengd: 0,
      Lokadags_Skraningar: '',
      Lykur_KL: '00:00',
      Lysing_flokkud_1: '',
      Lysing_flokkud_2: '',
      Lysing_flokkud_3: '',
      Lysing_flokkud_4: '',
      Lysing_flokkud_5: '',
      Markmid: '',
      Mynd: url + category.avatar,
      Mynd_Vefslod: url + category.avatar_url,
      Myndband_Mynd_Vefslod: '',
      Myndband_Vefslod: '',
      Namskeid_Id: category.id,
      Namskeidsflokkur: '',
      Namsefni: '',
      Namsmat: '',
      Numer: '',
      Nytt_Endurtekid_: '',
      Pdf_Slod: '',
      Samvinnutexti: '',
      Skilmalar: '',
      Stadsetning: '',
      Stjornur: '',
      Stutt_Lysing: 'Test lýsing',
      Stutt_Lysing_Ny: '',
      Taekjabunadur: '',
      Tegund_eydublads: '',
      Textaleit: '',
      Timi: '',
      Umsagnir: '',
      Umsoknarferli: '',
      Umsjon: '',
      Umsjon_almennt: '',
      Umsjon_Mynd: url + category.avatar_url,
      Umsjon_netfang: '',
      Umsjon_simi: '',
      Undirflokkur: category.parent_id ? category.name : '',
      Undirtitill: '',
      Verd: 'parseFloat(category.cost)',
      Verd_Snemmskraning: '',
      Text_Snemmskraning: '',
      Yfirflokkur: !category.parent_id ? category.name : '',
      rafraenskjol: '',
    } as unknown as Namskeid;
    return courseFakeMaker;
  }

  createPortalData(
    images_courses: string,
    image: string,
    extra_review: Map<string, string>[]
  ): PortalData {
    return {
      Myndir_Namskeid: [
        {
          Myndir_Namskeid: images_courses,
          Mynd: image,
        },
      ],
      Auka_Umsagnir_Vefur: [],
    };
  }

  async getAllCategories(): Promise<Category[]> {
    return this.apiService.getAllCategories();
  }

  async getAllEducators(): Promise<EducatorWithIncludes> {
    return this.apiService.getAllEducators();
  }

  async getAllMeetings(): Promise<GetMeetingsResponse> {
    return this.apiService.getAllMeetings();
  }

  async getAllCourseVariants(): Promise<GetCourseVariantsResponse> {
    return this.apiService.getAllCourseVariants();
  }

  async getAllCourseLocations(): Promise<GetCourseLocationsResponse> {
    return this.apiService.getAllCourseLocations();
  }

  async getAllLabels(params?: GetLabelsData): Promise<Label[]> {
    return this.apiService.getAllLabels(params);
  }

  async getAllCourseTabs(): Promise<GetCourseTabsResponse> {
    return this.apiService.getAllCourseTabs();
  }

  async getAllProgramEditions(): Promise<GetProgramEditionsResponse> {
    return this.apiService.getAllProgramEditions();
  }

  async getAllCourses(): Promise<GetCoursesResponse> {
    return this.apiService.getAllCourses();
  }

  async getAllTeachers(): Promise<GetTeachersResponse> {
    return this.apiService.getAllTeachers();
  }

  async getAllCatalogProducts(
    params: GetCatalogProductsData
  ): Promise<GetCatalogProductsResponse> {
    return this.apiService.getAllCatalogProducts(params);
  }

  async getAllCatalogVariants(
    params: GetCatalogVariantsData
  ): Promise<GetCatalogVariantsResponse> {
    return this.apiService.getAllCatalogVariants(params);
  }

  async getAllTeacherEnrollments(): Promise<GetTeacherEnrollmentsResponse> {
    return this.apiService.getAllTeacherEnrollments();
  }

  async generateCourseMap(
    courses: GetCoursesResponse,
    key: string | number = 'id'
  ): Promise<CourseMap> {
    const courseMap: CourseMap = {};
    courses.forEach(course => {
      courseMap[course[key]] = course;
    });
    return courseMap;
  }

  async generateCourseVariantMap(
    courseVariants: GetCourseVariantsResponse,
    key: string | number = 'id'
  ): Promise<CourseVariantMap> {
    const courseVariantMap: CourseVariantMap = {};
    courseVariants.forEach(courseVariant => {
      courseVariantMap[courseVariant[key]] = courseVariant;
    });
    return courseVariantMap;
  }

  async generateCourseLocationMap(
    courseLocations: GetCourseLocationsResponse,
    key: string | number = 'id'
  ): Promise<CourseLocationMap> {
    const courseLocationMap: CourseLocationMap = {};
    courseLocations.forEach(courseLocation => {
      courseLocationMap[courseLocation[key]] = courseLocation;
    });
    return courseLocationMap;
  }

  async generatePlannedCourseMap(
    plannedCourses: GetPlannedCoursesResponse,
    key: string | number = 'id'
  ): Promise<PlannedCourseMap> {
    const plannedCourseMap: PlannedCourseMap = {};
    plannedCourses.forEach(plannedCourse => {
      plannedCourseMap[plannedCourse[key] as string | number] = [plannedCourse];
    });
    return plannedCourseMap;
  }

  async generateLabelMap(
    labels: Label[],
    key: string | number = 'id'
  ): Promise<LabelMap> {
    const labelsMap: LabelMap = {};
    labels.forEach(label => {
      labelsMap[label[key]] = label;
    });
    return labelsMap;
  }

  async generateCatalogProductMap(
    catalog_products: GetCatalogProductsResponse,
    key: string | number = 'id'
  ): Promise<CatalogProductMap> {
    const catalogProductMap: CatalogProductMap = {};
    catalog_products.forEach(catalog_product => {
      catalogProductMap[catalog_product[key]] = catalog_product;
    });
    return catalogProductMap;
  }

  async generateTeacherMap(
    teachers: GetTeachersResponse,
    key: string | number = 'id'
  ): Promise<TeacherMap> {
    const teacherMap: TeacherMap = {};
    teachers.forEach(teacher => {
      teacherMap[teacher[key]] = teacher;
    });
    return teacherMap;
  }

  async generateTeacherEnrollmentMap(
    teacherEnrollments: GetTeacherEnrollmentsResponse,
    key: string | number = 'id'
  ): Promise<TeacherEnrollmentMap> {
    const teacherEnrollmentMap: TeacherEnrollmentMap = {};
    teacherEnrollments.forEach(teacherEnrollment => {
      teacherEnrollmentMap[teacherEnrollment[key]] = teacherEnrollment;
    });
    return teacherEnrollmentMap;
  }

  async generateTeacherEnrollmentMapArr(
    teacherEnrollments: GetTeacherEnrollmentsResponse,
    key: string | number = 'id'
  ): Promise<TeacherEnrollmentMapArr> {
    const teacherEnrollmentMap: TeacherEnrollmentMapArr = {};
    teacherEnrollments.forEach(teacherEnrollment => {
      if (!teacherEnrollmentMap[teacherEnrollment[key]]) {
        teacherEnrollmentMap[teacherEnrollment[key]] = [];
      }
      teacherEnrollmentMap[teacherEnrollment[key]].push(teacherEnrollment);
    });
    return teacherEnrollmentMap;
  }

  async generateCatalogVariantMap(
    catalogVariants: GetCatalogVariantsResponse,
    key: string | number = 'id'
  ): Promise<CatalogVariantMap> {
    const catalogVariantMap: CatalogVariantMap = {};
    catalogVariants.forEach(catalogVariant => {
      catalogVariantMap[catalogVariant[key]] = catalogVariant;
    });
    return catalogVariantMap;
  }

  async generateCourseTabMap(
    courseTabs: GetCourseTabsResponse,
    key: string | number = 'id'
  ): Promise<CourseTabMap> {
    const courseTabMap: CourseTabMap = {};
    courseTabs.forEach(courseTab => {
      courseTabMap[courseTab[key]] = courseTab;
    });
    return courseTabMap;
  }

  async generateProgramEditionMap(
    programEditions: GetProgramEditionsResponse,
    key: string | number = 'id'
  ): Promise<ProgramEditionMap> {
    const programEditionMap: ProgramEditionMap = {};
    programEditions.forEach(programEdition => {
      programEditionMap[programEdition[key] as string | number] =
        programEdition;
    });
    return programEditionMap;
  }

  async generateProgramMap(
    programs: GetProgramsResponse,
    key: string | number = 'id'
  ): Promise<ProgramMap> {
    const programMap: ProgramMap = {};
    programs.forEach(program => {
      programMap[program[key] as string | number] = program;
    });
    return programMap;
  }

  async generateMeetingMap(
    meetings: MeetingWithIncludes[],
    key: string | number = 'id'
  ): Promise<MeetingMap> {
    const meetingMap: MeetingMap = {};
    meetings.forEach(meeting => {
      if (!meetingMap[meeting[key] as string | number]) {
        meetingMap[meeting[key] as string | number] = [];
      }
      meetingMap[meeting[key] as string | number].push(meeting);
    });
    return meetingMap;
  }

  async fetchAndPrepereCourseData(params?: FilterParams, offset?, limit?) {
    // Keep your times array
    let times: string[] = [];

    // 1) Fetch the main planned courses
    const plannedCourseResponse: GetPlannedCoursesResponse =
      await this.apiService.fetchAllPages(
        this.apiService.eduframePlannedCoursesService.getPlannedCourses.bind(
          this.apiService.eduframePlannedCoursesService
        ),
        {
          search: params?.search,
          parentsPublished: 'parents_published',
          publishedPublic: 'published_public',
          sort: 'start_date:asc',
        }
      );

    // Fill times with a default value for each course
    for (let i = 0; i < plannedCourseResponse.length; i++) {
      times.push('00:00');
    }

    // 2) Fetch planned courses for "today"
    const plannedCourseResponseToday: GetPlannedCoursesResponse =
      await this.apiService.fetchAllPages(
        this.apiService.eduframePlannedCoursesService.getPlannedCourses.bind(
          this.apiService.eduframePlannedCoursesService
        ),
        {
          search: params?.search,
          parentsPublished: 'parents_published',
          startDateFrom: new Date().toISOString().split('T')[0],
          startDateUntil: new Date().toISOString().split('T')[0],
          sort: 'start_date:asc',
        }
      );

    // 3) Loop over "today" courses, do your meeting checks, then unshift
    plannedCourseResponseToday.forEach(async plannedCourse => {
      const paramsData = {
        plannedCourseId: plannedCourse.id,
        status: 'active',
        availability_state: 'open',
      } as unknown as GetMeetingsByPlannedCourseIdData;

      // Fetch meeting data
      const meeting = await this.apiService.eduframeMeetingsService
        .getMeetingsByPlannedCourseId(paramsData)
        .toPromise();

      if (!meeting || meeting.length === 0) {
        return;
      }
      const meetingDateTime = meeting[0].start_date_time;
      const meetingDate = new Date(meetingDateTime);
      const now = new Date();

      // Extract "HH:MM" from e.g. "2025-05-10T14:00:00.000Z"
      const [datePart, timePartWithZone] = meetingDateTime.split('T');
      const [timePart] = timePartWithZone.split('.');
      const [hour, minute] = timePart.split(':');
      const time = `${hour}:${minute}`;

      if (meetingDate >= now) {
        // Unshift the item and its time
        plannedCourseResponse.unshift(plannedCourse);
        times.unshift(time);
      }
    });

    {
      const uniqueCourses: GetPlannedCoursesResponse = [];
      const uniqueTimes: string[] = [];
      const seenIds = new Set<number>();

      // Keep first occurrence, skip duplicates
      for (let i = 0; i < plannedCourseResponse.length; i++) {
        const pc = plannedCourseResponse[i];
        if (!seenIds.has(pc.id)) {
          seenIds.add(pc.id);
          uniqueCourses.push(pc);
          uniqueTimes.push(times[i]);
        }
      }

      // Reassign so the rest of the code sees no duplicates
      plannedCourseResponse.length = 0;
      plannedCourseResponse.push(...uniqueCourses);

      times.length = 0;
      times.push(...uniqueTimes);
    }

    // 5) Fetch the other data in parallel
    const [
      categories,
      courses,
      course_variants,
      course_locations,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
    ] = await Promise.all([
      this.getAllCategories(),
      this.getAllCourses(),
      this.getAllCourseVariants(),
      this.getAllCourseLocations(),
      this.getAllLabels({ modelType: 'Catalog::Product' }),
      this.getAllLabels({ modelType: 'Teacher' }),
      this.getAllTeachers(),
      this.getAllCatalogProducts({}),
      this.getAllCatalogVariants({}),
      this.getAllTeacherEnrollments(),
      this.getAllCourseTabs(),
    ]);

    const parentCategories = categories.filter(category => !category.parent_id);

    let subCategoriesMap: SubCategoryMap = {};
    parentCategories.forEach(parentCategory => {
      subCategoriesMap[parentCategory.id] = categories.filter(
        category => category.parent_id === parentCategory.id
      );
    });

    // 6) Return your final object
    return {
      plannedCourseResponse,
      categories,
      courses,
      courseVariants: course_variants,
      courseLocations: course_locations,
      label_courses,
      label_teachers,
      parentCategories,
      subCategoriesMap,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
      times,
    };
  }

  async fetchAndPrepereClassData(params?: FilterParams, offset?, limit?) {
    const programPromise = this.apiService.eduframeProgramsService
      .getPrograms({ perPage: 500 })
      .toPromise();
    const programEditionPromise = this.apiService.eduframeProgramEditionsService
      .getProgramEditions({ perPage: 500 })
      .toPromise();

    const [
      categories,
      course_variants,
      course_locations,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
    ] = await Promise.all([
      this.getAllCategories(),
      this.getAllCourseVariants(),
      this.getAllCourseLocations(),
      this.getAllLabels({ modelType: 'Catalog::Product' }),
      this.getAllLabels({ modelType: 'Teacher' }),
      this.getAllTeachers(),
      this.getAllCatalogProducts({
        productableType: 'Program::Program',
        search: params?.search,
      } as GetCatalogProductsData),
      this.getAllCatalogVariants({
        variantableType: 'program_edition',
      } as GetCatalogVariantsData),
      this.getAllTeacherEnrollments(),
      this.getAllCourseTabs(),
    ]);

    const programResponse = (await programPromise) as unknown as GetProgramsResponse;
    const programEditionResponse = (await programEditionPromise) as GetProgramEditionsResponse;

    const catalogProductMap: CatalogProductMap = await this.generateCatalogProductMap(
      catalog_products,
      'productable_id'
    );
    const catalogVariantMap: CatalogVariantMap = await this.generateCatalogVariantMap(
      catalog_variants,
      'variantable_id'
    );
    const programEditionMap: ProgramEditionMap = await this.generateProgramEditionMap(
      programEditionResponse,
      'program_id'
    );
    const programMap = await this.generateProgramMap(programResponse);

    let plannedCourseResponse: GetPlannedCoursesResponse = [];
    let courseResponse: GetCoursesResponse = [];
    let times: string[] = []; // Initialize an array to store times

    catalog_products.forEach(catalogProduct => {
      const programEdition = programEditionMap[catalogProduct.productable_id];

      if (programEdition === undefined) {
        return;
      }

      const catalogVariant = catalogVariantMap[programEdition.id];
      const program = programMap[programEdition.program_id];

      const start_date_date = new Date(programEdition.start_date);
      const date_now = new Date(Date.now());

      plannedCourseResponse.push({
        id: programEdition.id,
        course_location_id: null,
        course_variant_id: program.id,
        status: start_date_date < date_now ? 'active' : 'planned',
        availability_state: null,
        course_id: program.id,
        type: 'FixedPlannedCourse',
        start_date: programEdition.start_date,
        end_date: programEdition.end_date,
        min_participants: programEdition.min_participants,
        max_participants: programEdition.max_participants,
        confirmed_active_and_completed_enrollments_count: programEdition.current_participants,
        requested_enrollments_count: programEdition.current_participants,
        cost_scheme: catalogVariant.cost_scheme,
        is_published: programEdition.is_published,
        updated_at: catalogProduct?.updated_at ?? '',
        created_at: catalogProduct?.created_at ?? '',
        duration_in_days: 1,
        cost: programEdition.cost,
        payable: true,
        current_participants: programEdition.current_participants,
        available_places: catalogVariant.available_places > 0 ? true : false,
        currency: catalogVariant.currency,
        cost_multiplier: '1',
      });

      courseResponse.push({
        id: program.id,
        category_id: catalogProduct?.category_id ?? 0,
        name: programEdition.name,
        code: null,
        duration: null,
        level: null,
        meta_title: '',
        meta_description: '',
        result: '',
        cost: programEdition.cost,
        cost_scheme: catalogVariant?.cost_scheme ?? undefined,
        is_published: programEdition.is_published,
        position: null,
        slug: catalogProduct?.slug ?? '',
        slug_history: [],
        updated_at: catalogProduct?.updated_at ?? '',
        created_at: catalogProduct?.created_at ?? '',
        certificate_template_id: null,
        starting_price: null,
        signup_url: catalogProduct?.signup_url ?? '',
        conditions_or_default: catalogProduct?.conditions_or_default ?? '',
        website_url: '',
        label_ids: catalogProduct?.label_ids ?? [],
        avatar: catalogProduct?.avatar ?? '',
        avatar_url: catalogProduct?.avatar ?? '',
        avatar_thumb_url: catalogProduct?.avatar ?? '',
        custom: {},
      });

      // Since this function is not fetching meeting times, add a placeholder '00:00' for each class
      times.push('00:00');
    });

    // (Optional) Deduplication block if needed
    {
      const uniqueCourses: GetPlannedCoursesResponse = [];
      const uniqueTimes: string[] = [];
      const seenIds = new Set<number>();

      for (let i = 0; i < plannedCourseResponse.length; i++) {
        const pc = plannedCourseResponse[i];
        if (!seenIds.has(pc.id)) {
          seenIds.add(pc.id);
          uniqueCourses.push(pc);
          uniqueTimes.push(times[i]);
        }
      }
      plannedCourseResponse = uniqueCourses;
      times = uniqueTimes;
    }

    // ***** NEW: Apply search filtering (if a query is provided) *****
    if (params?.search) {
      const searchLower = params.search.toLowerCase();
      const filteredPlanned: GetPlannedCoursesResponse = [];
      const filteredCourses: GetCoursesResponse = [];
      const filteredTimes: string[] = [];
      for (let i = 0; i < courseResponse.length; i++) {
        // Filter classes by matching the class name (from courseResponse)
        if (courseResponse[i].name.toLowerCase().includes(searchLower)) {
          filteredPlanned.push(plannedCourseResponse[i]);
          filteredCourses.push(courseResponse[i]);
          filteredTimes.push(times[i]);
        }
      }
      plannedCourseResponse = filteredPlanned;
      courseResponse = filteredCourses;
      times = filteredTimes;
    }
    // **************************************************************

    const parentCategories = categories.filter(category => !category.parent_id);

    let subCategoriesMap: SubCategoryMap = {};
    parentCategories.forEach(parentCategory => {
      subCategoriesMap[parentCategory.id] = categories.filter(
        category => category.parent_id === parentCategory.id
      );
    });

    return {
      plannedCourseResponse,
      categories,
      courses: courseResponse,
      courseVariants: course_variants,
      courseLocations: course_locations,
      label_courses,
      label_teachers,
      parentCategories,
      subCategoriesMap,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
      times, // Return the times array as well
    };
  }




  async fetchAndPrepereSingleCourseData(
    course_id: number,
    planned_course_id: number,
    params?: FilterParams,
    offset?: number,
    limit?: number
  ): Promise<PlannedCourseWithQueryParamsResponse | null> {
    const {
      plannedCourseResponse,
      categories,
      courses,
      courseVariants,
      courseLocations,
      parentCategories,
      subCategoriesMap,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
      times,
    } = await this.fetchAndPrepereCourseData();

    const courseMap = await this.generateCourseMap(courses);
    const courseVariantMap = await this.generateCourseVariantMap(
      courseVariants
    );
    const courseLocationMap = await this.generateCourseLocationMap(
      courseLocations
    );
    const plannedCourseMap = await this.generatePlannedCourseMap(
      plannedCourseResponse,
      'id'
    );
    const catalogProductMap = await this.generateCatalogProductMap(
      catalog_products,
      'productable_id'
    );
    const catalogVariantMap = await this.generateCatalogVariantMap(
      catalog_variants,
      'variantable_id'
    );
    const course_label_map = await this.generateLabelMap(label_courses);
    const teacherMap = await this.generateTeacherMap(teachers);
    const teacherEnrollmentMapByPlannedCourseId =
      await this.generateTeacherEnrollmentMapArr(
        teacher_enrollments,
        'planned_course_id'
      );

    const plannedCourse = plannedCourseMap[planned_course_id];
    const teacherEnrollment =
      teacherEnrollmentMapByPlannedCourseId[planned_course_id];
    let t = [];
    teacherEnrollment?.forEach(enrollment => {
      const teacher = teacherMap[enrollment.teacher_id];
      t.push(teacher);
    });

    const courseTabMap = await this.generateCourseTabMap(course_tabs);

    const catalogProducts = catalogProductMap[course_id];
    const catalogProductCourseTabs = catalogProducts?.course_tab_contents;
    const catalogProductsTitle = catalogProductCourseTabs?.map(
      course_tab => courseTabMap[course_tab.course_tab_id]?.name
    );

    // take the first index of the meeting instead of some imaginary index with planned_course_id
    const meetingTime = times[0] || '00:00'; // Use times array or default to '00:00'



    const data: PlannedCourseWithQueryParamsResponse = {
      ...plannedCourseMap[planned_course_id][0],
      course: courseMap[course_id],
      course_variant:
        courseVariantMap[
          plannedCourseMap[planned_course_id][0].course_variant_id
        ],
      course_location:
        courseLocationMap[
          plannedCourseMap[planned_course_id][0].course_location_id
        ],
      teachers: t,
      catalog_product: catalogProducts,
      catalog_variant: catalogVariantMap[planned_course_id],
      course_tab_titles: catalogProductsTitle,
      meetingTime: meetingTime, // Add meetingTime to the response
    };

    return data;
  }

  /**
   * Fetches and prepares data for a single class
   * @param program_edition_id - Acts as the planned_course_id for classes
   * @param program_id - Acts as the course_id for classes
   */
  async fetchAndPrepereSingleClassData(
    program_id: number,
    program_edition_id: number,
    params?: FilterParams,
    offset?: number,
    limit?: number
  ): Promise<PlannedCourseWithQueryParamsResponse | null> {
    // Fetching all necessary data
    const {
      plannedCourseResponse,
      categories,
      courses,
      courseVariants,
      courseLocations,
      parentCategories,
      subCategoriesMap,
      label_courses,
      label_teachers,
      teachers,
      catalog_products,
      catalog_variants,
      teacher_enrollments,
      course_tabs,
    } = await this.fetchAndPrepereClassData();

    const courseMap = await this.generateCourseMap(courses);
    const courseVariantMap = await this.generateCourseVariantMap(
      courseVariants
    );
    const courseLocationMap = await this.generateCourseLocationMap(
      courseLocations
    );
    const plannedCourseMap = await this.generatePlannedCourseMap(
      plannedCourseResponse,
      'id'
    );
    const catalogProductMap = await this.generateCatalogProductMap(
      catalog_products,
      'productable_id'
    );
    const catalogVariantMap = await this.generateCatalogVariantMap(
      catalog_variants,
      'variantable_id'
    );
    const course_label_map = await this.generateLabelMap(label_courses);
    const teacherMap = await this.generateTeacherMap(teachers);
    const teacherEnrollmentMapByPlannedCourseId =
      await this.generateTeacherEnrollmentMapArr(
        teacher_enrollments,
        'planned_course_id'
      );

    const plannedCourse = plannedCourseMap[program_edition_id];
    const teacherEnrollment =
      teacherEnrollmentMapByPlannedCourseId[program_edition_id];
    let t = [];
    teacherEnrollment?.forEach(enrollment => {
      const teacher = teacherMap[enrollment.teacher_id];
      t.push(teacher);
    });

    const courseTabMap = await this.generateCourseTabMap(course_tabs);

    const catalogProducts = catalogProductMap[program_id];
    const catalogProductCourseTabs = catalogProducts?.course_tab_contents;
    const catalogProductsTitle = catalogProductCourseTabs?.map(
      course_tab => courseTabMap[course_tab.course_tab_id]?.name
    );


    const data: PlannedCourseWithQueryParamsResponse = {
      ...plannedCourseMap[program_edition_id][0],
      course: courseMap[program_id],
      course_variant:
        courseVariantMap[
          plannedCourseMap[program_edition_id][0].course_variant_id
        ],
      course_location:
        courseLocationMap[
          plannedCourseMap[program_edition_id][0].course_location_id
        ],
      teachers: t,
      catalog_product: catalogProducts,
      catalog_variant: catalogVariantMap[program_edition_id],
      course_tab_titles: catalogProductsTitle,
    };

    return data;
  }
}
