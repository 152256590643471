import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  PlannedCoursesService,
  CategoriesService,
  CoursesService,
  EducatorsService,
  MeetingsService,
  CourseVariantsService,
  CourseLocationsService,
  LabelsService,
  CourseTabsService,
  ProgramEditionsService,
  CatalogVariantsService,
  CatalogProductsService,
  TeachersService,
  TeacherEnrollmentsService,
  GetLabelsData,
  GetCatalogProductsResponse,
  GetTeachersResponse,
  GetCatalogVariantsResponse,
  GetTeacherEnrollmentsResponse,
  GetCatalogVariantsData,
  GetCourseTabsResponse,
  GetPlannedCoursesResponse,
  GetProgramsResponse,
  GetProgramEditionsResponse,
  GetCatalogProductsData,
  ProgramProgramsService,
  Category,
  EducatorWithIncludes,
  GetCourseLocationsResponse,
  GetCoursesResponse,
  GetCourseVariantsResponse,
  GetMeetingsResponse,
  Label,
  OpenAPI,
  GetTeachersData,
  Variant,
  VariantWithIncludes,
} from 'src/eduframe';
import { CatalogVariantCustom } from './interfaces/fake-maker.interface';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    public eduframeCoursesService: CoursesService,
    public eduframeEducatorsService: EducatorsService,
    public eduframePlannedCoursesService: PlannedCoursesService,
    public eduframeCategoriesService: CategoriesService,
    public eduframeMeetingsService: MeetingsService,
    public eduframeCourseVariantsService: CourseVariantsService,
    public eduframeCourseLocationsService: CourseLocationsService,
    public eduframeLabelsService: LabelsService,
    public eduframeCourseTabsService: CourseTabsService,
    public eduframeProgramEditionsService: ProgramEditionsService,
    public eduframeCatalogProductService: CatalogProductsService,
    public eduframeCourseTabsContentService: CourseTabsService,
    public eduframeTeachersService: TeachersService,
    public eduframeTeacherEnrollmentsService: TeacherEnrollmentsService,
    public eduframeCatalogVariantService: CatalogVariantsService,
    public eduframeProgramsEditionService: ProgramEditionsService,
    public eduframeProgramsService: ProgramProgramsService
  ) {
    OpenAPI.BASE = '/api';
  }

  async fetchAllPages<T>(
    fetchFunction: (params: any) => Observable<T[]>,
    params: any
  ): Promise<T[]> {
    let page = 1;
    let results: T[] = [];
    let response: T[];

    do {
      const currentParams = { ...params, page, perPage: 100 };
      response = await new Promise<T[]>((resolve, reject) => {
        fetchFunction(currentParams).subscribe({
          next: resolve,
          error: reject,
        });
      });
      results = results.concat(response);
      page++;
    } while (response.length === 100);

    return results;
  }

  // API Methods to fetch data
  async getAllCategories(): Promise<Category[]> {
    return this.fetchAllPages(
      this.eduframeCategoriesService.getCategories.bind(
        this.eduframeCategoriesService
      ),
      {}
    );
  }

  async getAllEducators(): Promise<EducatorWithIncludes> {
    return this.eduframeEducatorsService.getCurrentEducator().toPromise();
  }

  async getAllMeetings(): Promise<GetMeetingsResponse> {
    return this.eduframeMeetingsService.getMeetings().toPromise();
  }

  async getAllCourseVariants(): Promise<GetCourseVariantsResponse> {
    return this.fetchAllPages(
      this.eduframeCourseVariantsService.getCourseVariants.bind(
        this.eduframeCourseVariantsService
      ),
      {}
    );
  }

  async getAllCourseLocations(): Promise<GetCourseLocationsResponse> {
    return this.fetchAllPages(
      this.eduframeCourseLocationsService.getCourseLocations.bind(
        this.eduframeCourseLocationsService
      ),
      {}
    );
  }

  async getAllLabels(params?: GetLabelsData): Promise<Label[]> {
    return this.fetchAllPages(
      this.eduframeLabelsService.getLabels.bind(this.eduframeLabelsService),
      params
    );
  }

  async getAllCourseTabs(): Promise<GetCourseTabsResponse> {
    return this.eduframeCourseTabsService.getCourseTabs().toPromise();
  }

  async getAllProgramEditions(): Promise<GetProgramEditionsResponse> {
    return this.fetchAllPages(
      this.eduframeProgramEditionsService.getProgramEditions.bind(
        this.eduframeProgramEditionsService
      ),
      {}
    );
  }

  async getAllCourses(): Promise<GetCoursesResponse> {
    return this.fetchAllPages(
      this.eduframeCoursesService.getCourses.bind(this.eduframeCoursesService),
      { published: 'published' }
    );
  }

  async getAllTeachers(params?: GetTeachersData): Promise<GetTeachersResponse> {
    return this.fetchAllPages(
      this.eduframeTeachersService.getTeachers.bind(
        this.eduframeTeachersService
      ),
      params
    );
  }

  async getAllCatalogProducts(
    params: GetCatalogProductsData
  ): Promise<GetCatalogProductsResponse> {
    return this.fetchAllPages(
      this.eduframeCatalogProductService.getCatalogProducts.bind(
        this.eduframeCatalogProductService
      ),
      params
    );
  }


  // TODO => this method should be retracted back to the original method after the customer has updated their API
// TODO: Remove the "skraningarform_slod" override once the backend returns it for real.
async getAllCatalogVariants(
  params: GetCatalogVariantsData
): Promise<GetCatalogVariantsResponse> {
  // 1. Fetch all pages (returns an array of variants)
  const response = await this.fetchAllPages(
    this.eduframeCatalogVariantService.getCatalogVariants.bind(
      this.eduframeCatalogVariantService
    ),
    params
  );

  // 2. Temporarily inject "skraningarform_slod" into each variant’s "custom" field
  return response.map((variant: VariantWithIncludes) => ({
    ...variant,
    custom: {
      ...((variant.custom as CatalogVariantCustom) || {}),
      "island-is-umsoknarhlekkur": variant.custom["island-is-umsoknarhlekkur"]
    }
  }));
}



  async getAllTeacherEnrollments(): Promise<GetTeacherEnrollmentsResponse> {
    return this.fetchAllPages(
      this.eduframeTeacherEnrollmentsService.getTeacherEnrollments.bind(
        this.eduframeTeacherEnrollmentsService
      ),
      {}
    );
  }
}
